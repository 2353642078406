.footer {
  background: #291528;
  color: #f8f8f8;
  font-size: 1.2em;
  height: auto;
  padding: 10px 10px;
  text-align: left;
}
.footer-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.social-media {
  color: 'white';
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}
.social-media-item {
  color: #ddd;
  font-size: 1.8em;
  padding-right: 20px;
  transition: all 0.25s ease-in-out;
}
.facebook:hover {
  color: #3d558f;
}
.twitter:hover {
  color: #2a9deb;
}
.instagram:hover {
  color: #98569d;
}
.linkedin:hover {
  color: #0077b5;
}
.twitter-link {
  text-decoration: none;
  color: #aaa;
}
