.header {
  background: #291528;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 101px;
}
.header-mobile {
  background: #291528;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 101px;
}
.navbar {
  color: #f8f8f8;
  font-size: 1.2em;
}
.navbar-link {
  margin-right: 15px;
  cursor: pointer;
}
.navbar-link:last-child {
  margin-right: 10px;
}
.active-link {
  font-weight: bold;
}
.sticky-header {
  z-index: 998;
  height: 100px;
}
.hamburger-react {
  z-index: 1000;
}

.hamburger-react > div {
  z-index: 1000;
}
