.bio-image {
  max-height: 250px;
  padding-bottom: 20px;
}
.bio-name {
  font-weight: bold;
  padding-bottom: 8px;
}
.bio-title {
  font-style: italic;
  padding-bottom: 8px;
}

.bio-description {
  padding-bottom: 10px;
}

.bio-last-line {
}
/* with image, make sure to use max-height AND max-width */
