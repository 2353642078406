.sections {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.sections > div {
  margin-bottom: 30px;
}

.all-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.all-parallax-mobile {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.top-parallax {
  height: calc(100vh - 101px);
  border-bottom: 15pt solid #291528;
  width: auto;
}

.middle-parallax {
  height: 40vh;
  border-bottom: 15pt solid #291528;
  border-top: 15pt solid #291528;
}

.all-buttons {
  padding: 8px;
  border-radius: 10px;
  color: #f8f8f8;
  font: inherit;
}

.all-buttons:hover {
  color: #291528;
  background-color: #d6ba73;
  box-shadow: 0px 0px 10px #291528;
}

.parallax-mobile {
  height: 80vh;
  width: auto;
}
/* this is the correct property, but only works w/ important.  Where should this go instead */

/* .mobile-parallax {
}

#mobile-image-display {
	display: none;
	padding: 0px;
}

#mobile-image-display-image {
	padding: 0px;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.parallax {
		display: none;
	}
	.custom-logo {width: 30%;}
	#mobile-image-display {display: block;}
	.mobile-parallax {
		display: block;
	} */
