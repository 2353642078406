.four-cs-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.four-cs-container > div {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
}

.four-cs-image {
  height: 205px;
  width: 197px;
}

.four-cs {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
