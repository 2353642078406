.contact-button {
  background-color: #008080;
  variant: contained;
  font-weight: bold;
}

.contact-button-inner {
  text-transform: none;
  padding: 10px 20px 10px 20px;
}
