.form-container {
  background-color: #008080;
  color: #f8f8f8;
  width: 60vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  font-weight: bold;
}

.form-container-mobile {
  background-color: #008080;
  color: #f8f8f8;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border-radius: 6px;
  padding: 10px;
  font-weight: bold;
}
.rfp-form {
  width: 100%;
  /* 
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  flex: 1; */
}

.input-div {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.input-field {
  width: 90%;
  border-radius: 4px;
  height: 30px;
  background-color: #e6ffff;
  border: none;
}

.text-field {
  width: 98%;
  border-radius: 4px;
  background-color: #e6ffff;
  border: none;
}

.question {
  margin-bottom: 3px;
}

.submit-button {
  width: 200px;
  height: 50px;
  background-color: #291528;
  border: none;
  justify-content: center;
}

.form-top {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.form-top-fields {
  flex: 1;
  width: 100%;
  margin-right: 25px;
}

.form-top-stamp {
  display: flex;
  justify-content: center;
  align-items: center;
}
