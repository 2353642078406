.about-us-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.bio {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.reviews-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.reviews-container > div {
  margin-right: 20px;
}

.reviews-container > div:last-child {
  margin-right: 0;
}

.reviews-container-mobile > div {
  margin-right: 0px;
  margin-bottom: 15px;
}
.review {
  font-style: italic;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 20px;

  border-radius: 10px;
}
