.section-header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.section-header-separator {
  border-top: 1.5px solid #291528;
  position: relative;
  top: 1px;
  width: 60%;
  box-sizing: border-box;
  flex: auto;
}
.section-header-words {
  flex: auto;
  font-size: 15pt;
  font-weight: bold;
  color: #008080;
  padding-left: 20px;
  padding-right: 20px;
}
